import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { carousel1 } from "../reusables/carousel1";
import { Slide } from "react-reveal";
import h1 from "../../assets/images/content/h1.jpg";
import h2 from "../../assets/images/content/h2.jpg";
import h3 from "../../assets/images/content/h3.jpg";
import star from "../../assets/images/icons/star.svg";

const RestaurantPageHero = () => {
  return (
    <section className="section page-hero">
      <div className="section-content-container">
        <div className="hero-slide carousel relative">
          <div className="slider-items-container center-slide-arrows">
            <Slider {...carousel1}>
              <div className="image-container curtain">
                <img src={h1} alt="Hotel Facility" className="pageSliderImg" />
              </div>
              <div className="image-container curtain">
                <img src={h2} alt="Hotel Facility" className="pageSliderImg" />
              </div>
              <div className="image-container curtain">
                <img src={h3} alt="Hotel Facility" className="pageSliderImg" />
              </div>
            </Slider>
          </div>

          <div className="container">
            <div className="large">
              <div className="hero-content-container center centered">
                <div className=" centered-inner">
                  <div className="best-section">
                    <p className="best-text">twin tower hotel uganda</p>
                    <div className="best-bars-container flex">
                      <div className="best-bar"></div>
                      <img src={star} className="best-star" />
                      <div className="best-bar"></div>
                    </div>
                  </div>
                  <div className="brand-section">
                    <h2 className="best-text">Restaurant</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RestaurantPageHero;
