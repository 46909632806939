import React from "react";
import { Slide } from "react-reveal";
import RoomCard from "./RoomCard";
import h2 from "../../assets/images/content/h3.jpg";
import h4 from "../../assets/images/content/h4.jpg";
import h6 from "../../assets/images/content/h6.jpg";
import h9 from "../../assets/images/content/h9.jpg";
import h8 from "../../assets/images/content/h8.jpg";
import h10 from "../../assets/images/content/h10.jpg";
import h11 from "../../assets/images/content/h11.jpg";
import h22 from "../../assets/images/content/h22.jpg";

const AccommodationBody = () => {
  return (
    <section className="section section-spacing-40">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <Slide top>
              <div className="section-head center width-300 margin-auto wavetitle-bg">
                <h3 className="section-title title">Hotel Rooms</h3>
              </div>
            </Slide>
            <div className="section-body section-spacing-80">
              <ul className="room-cards-container flex">
                <RoomCard
                  img1={h2}
                  img2={h6}
                  img4={h9}
                  img3={h10}
                  roomTitle="Single Deluxe"
                  roomLink="https://form.jotform.com/tthotelug/book-a-room"
                >
                  <li>Single Bed</li>
                  <li>Breakfast in bed</li>
                  <li>Price: 150,000 UGX</li>
                </RoomCard>
                <RoomCard
                  img1={h22}
                  img2={h4}
                  img3={h9}
                  img4={h10}
                  roomTitle="Double Deluxe"
                  roomLink="https://form.jotform.com/tthotelug/book-a-room"
                >
                  <li>Two Beds</li>
                  <li>Breakfast in bed</li>
                  <li>Price: 150,000 UGX</li>
                </RoomCard>
                <RoomCard
                  img2={h8}
                  img3={h9}
                  img4={h10}
                  img1={h11}
                  roomTitle="Single Standard"
                  roomLink="https://form.jotform.com/tthotelug/book-a-room"
                >
                  <li>Two Beds</li>
                  <li>Breakfast in bed</li>
                  <li>Price: 100,000 UGX</li>
                </RoomCard>
                <RoomCard
                  img1={h22}
                  img2={h4}
                  img3={h9}
                  img4={h10}
                  roomTitle="Double Standard"
                  roomLink="https://form.jotform.com/tthotelug/book-a-room"
                >
                  <li>Two Beds</li>
                  <li>Breakfast in bed</li>
                  <li>Price: 150,000 UGX</li>
                </RoomCard>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccommodationBody;
