import React from "react";
import { Slide } from "react-reveal";

const AboutBody = () => {
  return (
    <section className="section section-spacing-40">
      <div className="container wavetitle-bg">
        <div className="large">
          <div className="section-content-container">
            <Slide top>
              <div className="section-head center width-300 margin-auto">
                <h3 className="section-title title">
                  About<br></br> twin tower hotel
                </h3>
              </div>
            </Slide>
            <div className="section-body section-spacing-40 no-padding-bottom width-600 margin-auto">
              <div className="about-content">
                <p>
                  Welcome to Twin Tower Hotel! A serene and affordable
                  environment for a leisure treat. We are located 4 Kilometers
                  from City Center along Kampala - Bombo Road, Plot 934 kampala.
                </p>
                <p>
                  We are located 4 Kilometers from City Center along Kampala -
                  Bombo Road, Plot 934 kampala. It is a 45 Mins drive away from
                  Entebbe International Airport.
                </p>
              </div>
            </div>
          </div>
          <div className="section-content-container section-spacing-40 no-padding-bottom">
            <Slide top>
              <div className="section-head center width-300 margin-auto">
                <h3 className="section-title title wavetitle-bg">Offers</h3>
              </div>
            </Slide>
            <div className="section-body section-spacing-40 no-padding-bottom width-600 margin-auto">
              <div className="about-content">
                <h3 className="uppercase">Accommodation</h3>
                <p>
                  Affordable, self-contained, spacious rooms with healthy
                  beddings for you at Twin Tower Hotel.
                </p>
              </div>
              <div className="about-content">
                <h3 className="uppercase">Catering services</h3>
                <p>
                  Delicious meals and a wide range of cuisines available to
                  satisfy your hunger. We also offer outisde catering.
                </p>
              </div>
              <div className="about-content">
                <h3 className="uppercase">Free Wifi</h3>
                <p>
                  Miss no fun and vibes online. Get your online work done at the
                  comfort of our premises.
                </p>
              </div>
              <div className="about-content">
                <h3 className="uppercase">Meetings &amp; Events Facilities</h3>
                <p>
                  Spacious, equipped and affordable rooms available to meet your
                  gatherings and corporate events. Great for wedding receptions,
                  bridal and baby showers, birthday parties, wedding lunch,
                  corporate meetings and press conferences.
                </p>
              </div>
              <div className="about-content">
                <h3 className="uppercase">Power Backup</h3>
                <p>
                  Onsite heavy duty backup generator so that blackout doesnt
                  affect your fun.
                </p>
              </div>
              <div className="about-content">
                <h3 className="uppercase">Free Parking Space</h3>
                <p>
                  Spacious and secure for your vehicles under the watch of
                  security personnels.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBody;
