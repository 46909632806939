import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { carousel1 } from "../reusables/carousel1";

const FacilityCard = (props) => {
  return (
    <li className="room-card facility-card">
      <div className="images-slide">
        <div className="rooms-carousel carousel">
          <div className="margin-auto">
            <div className="gallery-slider slider-items-container center-slide-arrows">
              <Slider {...carousel1}>
                <img src={props.img1} />
                <img src={props.img2} />
                <img src={props.img3} />
                <img src={props.img4} />
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className="room-details">
        <h3 className="room-title">{props.facilityTitle}</h3>
        <ul>{props.children}</ul>
      </div>
      <div className="room-cta-container padding-8 center">
        <a
          target="_blank"
          href={props.facilityLink}
          className="room-cta light-yellow-bg uppercase dark bold button width-300"
        >
          Book
        </a>
      </div>
    </li>
  );
};

export default FacilityCard;
