import React from "react";
import { Slide } from "react-reveal";
import MenuCard from "./MenuCard";
import bf from "../../assets/images/menu/break.jpg";
import chicken from "../../assets/images/menu/chicken.jpg";
import fish from "../../assets/images/menu/fish.jpg";
import goat from "../../assets/images/menu/goat.jpg";
import pasta from "../../assets/images/menu/pasta.jpg";
import pizza from "../../assets/images/menu/pizza.jpg";
import sand from "../../assets/images/menu/sand.jpg";
import snacks from "../../assets/images/menu/snacks.jpg";
import snackspecials from "../../assets/images/menu/snackspecials.jpg";
import specials from "../../assets/images/menu/specials.jpg";
import hot from "../../assets/images/menu/hot.jpg";
import soft from "../../assets/images/menu/soft.jpg";
import wine from "../../assets/images/menu/wine.jpg";
import wines from "../../assets/images/menu/wines.jpg";
import teq from "../../assets/images/menu/teq.jpg";
import whisky from "../../assets/images/menu/whisky.jpg";
import vodka from "../../assets/images/menu/vodka.jpg";
import quarters from "../../assets/images/menu/quarters.jpg";

const RestaurantBody = () => {
  return (
    <section className="section section-spacing-40">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <Slide top>
              <div className="section-head center width-300 margin-auto wavetitle-bg">
                <h3 className="section-title title">Food Menu</h3>
              </div>
            </Slide>
            <div className="section-body section-spacing-40 no-padding-bottom">
              <ul className="menu-cards-container flex">
                <MenuCard menuTitle="Breakfast" menuImage={bf} />
                <MenuCard menuTitle="Chicken Dishes" menuImage={chicken} />
                <MenuCard menuTitle="Fish Dishes" menuImage={fish} />
                <MenuCard menuTitle="Goat Dishes" menuImage={goat} />
                <MenuCard menuTitle="Pizza" menuImage={pizza} />
                <MenuCard menuTitle="Sandwich &amp; Burgers" menuImage={sand} />
                <MenuCard menuTitle="Pasta &amp; Wraps" menuImage={pasta} />
                <MenuCard menuTitle="Snacks &amp; Soups" menuImage={snacks} />
                <MenuCard
                  menuTitle="Specials &amp; Stews"
                  menuImage={snackspecials}
                />
                <MenuCard menuTitle="Food Specials" menuImage={specials} />
              </ul>
            </div>
          </div>
          <div className="section-content-container section-spacing-50 no-padding-bottom">
            <Slide top>
              <div className="section-head center width-300 margin-auto wavetitle-bg">
                <h3 className="section-title title">Drinks Menu</h3>
              </div>
            </Slide>
            <div className="section-body section-spacing-40 no-padding-bottom">
              <ul className="menu-cards-container flex">
                <MenuCard menuTitle="Hot Beverages" menuImage={hot} />
                <MenuCard
                  menuTitle="Quarters &amp; Halves"
                  menuImage={quarters}
                />
                <MenuCard menuTitle="Soft Drinks" menuImage={soft} />
                <MenuCard menuTitle="Wines" menuImage={wine} />
                <MenuCard menuTitle="Wines by bottle" menuImage={wines} />
                <MenuCard menuTitle="Whiskeys" menuImage={whisky} />
                <MenuCard menuTitle="Vodkas" menuImage={vodka} />
                <MenuCard menuTitle="Tequilla" menuImage={teq} />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

{
  /*

<MenuCard menuTitle="Breakfast" menuImage={bf}>
                  <li>
                    <h4>TWIN TOWER HOTEL</h4>
                    <p>
                      Tea, Eggs of your Choice, Beef Sausages, Toasted Bread,
                      Fresh Juice, Greens with mushroom, Assorted Fruits &amp;
                      Grilled Tomatoes
                    </p>
                  </li>
                  <li>
                    <h4>KATOGO</h4>
                    <p>
                      Well-Seasoned Beef Cubes cooked with Matooke or Irish
                      Potatoes served with Vegetable aside
                    </p>
                  </li>
                </MenuCard>
                <MenuCard menuTitle="salads" menuImage={bf}>
                  <li>
                    <h4>GREEK SALAD</h4>
                    <p>
                      A combination of onions, tomatoes, cucumber, sweet pepper,
                      cheese nesting on a bed of crispy baby lettuce
                    </p>
                  </li>
                  <li>
                    <h4>FRESH CHICKEN CAESAR SALAD</h4>
                    <p>
                      Grilled chicken flakes, Bread croutons, tuna chunks,
                      parmesan cheese presented on crispy lettuce dressed with
                      seasoned cheesy vinaigrette
                    </p>
                  </li>
                  <li>
                    <h4>TWIN TOWER GARDEN SALAD</h4>
                    <p>
                      A combination of Fresh Vegetables in Season nicely done to
                      Chef’s perfection
                    </p>
                  </li>
                </MenuCard>
                <MenuCard menuTitle="TASTY SNACKS" menuImage={bf}>
                  <li>
                    <h4>FISH FINGERS TARTAR</h4>
                    <p>
                      Marinated fish flakes of Tilapia Fillet with lemon grinds
                      juice, white pepper, bread crumbs &amp; deep fried to
                      golden brown
                    </p>
                  </li>
                  <li>
                    <h4>SOOTHING CHICKEN LOLLIPOPS</h4>
                    <p>Glazed in alight BBQ Sauce to perfection</p>
                  </li>
                  <li>
                    <h4>STICKY CHICKEN WINGS</h4>
                    <p>Glazed in alight BBQ Sauce to perfection</p>
                  </li>
                  <li>
                    <h4>TENDER CHICKEN GIZZARDS</h4>
                    <p>
                      Sautéed Gizzards with onions. Garlic, Tomatoes finished
                      with bbq sauce
                    </p>
                  </li>
                  <li>
                    <h4>GOLDEN CHICKEN NUGGETS</h4>
                    <p>
                      Marinated pieces of Breast Chicken with pepper, Bread
                      Crumbs, Oil, Flour, deep fried to Perfection
                    </p>
                  </li>
                  <li>
                    <h4>BEEF SAUSAGES</h4>
                  </li>
                  <li>
                    <h4>ROLEX COMBO</h4>
                  </li>
                </MenuCard>
                <MenuCard menuTitle="salads" menuImage={bf}>
                  <li>
                    <h4>GREEK SALAD</h4>
                    <p>
                      A combination of onions, tomatoes, cucumber, sweet pepper,
                      cheese nesting on a bed of crispy baby lettuce
                    </p>
                  </li>
                  <li>
                    <h4>FRESH CHICKEN CAESAR SALAD</h4>
                    <p>
                      Grilled chicken flakes, Bread croutons, tuna chunks,
                      parmesan cheese presented on crispy lettuce dressed with
                      seasoned cheesy vinaigrette
                    </p>
                  </li>
                  <li>
                    <h4>TWIN TOWER GARDEN SALAD</h4>
                    <p>
                      A combination of Fresh Vegetables in Season nicely done to
                      Chef’s perfection
                    </p>
                  </li>
                </MenuCard>
                <MenuCard menuTitle="SANDWICH &amp; BURGERS" menuImage={bf}>
                  <li>
                    <h4>CHICKEN OR BEEF SANDWICH</h4>
                    <p>
                      Two bread slices, mayonnaise, chicken or beef infused with
                      vegetables
                    </p>
                  </li>
                  <li>
                    <h4>VEGETABLE SANDWICH</h4>
                    <p>Two bread slices layered with mayonnaise and vegs</p>
                  </li>
                  <li>
                    <h4>CHEESE &amp; TOMATO BEEF BURGER</h4>
                    <p>
                      Grilled Beef Patties Infused in Island dressing, dressed
                      burger bun topped with tomato slices, onions &amp; cheese
                    </p>
                  </li>
                  <li>
                    <h4>CHEESY CHICKEN BURGER</h4>
                    <p>
                      Grilled Beef Patties Infused in Island dressing, dressed
                      burger bun topped with tomato slices, onions &amp; cheese.
                    </p>
                  </li>
                  <li>
                    <h4>EXTRA BURGER TOPPINGS</h4>
                  </li>
                </MenuCard>
                <MenuCard menuTitle="CHICKEN DISHES" menuImage={bf}>
                  <li>
                    <h4>¼ CHICKEN</h4>
                    <p>
                      Oven roasted chicken marinated to your perfection with
                      rosemary leaves, garlic &amp; white pepper to golden
                      Brown. ACCOMPANIED WITH CHOICE OF EITHER CHIPS, RICE,
                      WEDGES
                    </p>
                  </li>
                  <li>
                    <h4>½ CHICKEN</h4>
                    <p>
                      Oven roasted chicken marinated to perfection with rosemary
                      leaves, garlic, and pepper served to perfection.
                      ACCOMPANIED WITH CHOICE OF EITHER CHIPS, RICE, WEDGES
                    </p>
                  </li>
                  <li>
                    <h4>GRILLED CHICKEN BREAST</h4>
                    <p>
                      Grilled chicken breast served with mushroom creamy gravy
                      aside. ACCOMPANIED WITH CHOICE OF EITHER CHIPS, RICE,
                      WEDGES
                    </p>
                  </li>
                  <li>
                    <h4>HEALTHY BOILED CHICKEN</h4>
                    <p>
                      Oil free chicken joints boiled with vegetable cubes &amp;
                      fresh herb flavors. ACCOMPANIED WITH CHOICE OF EITHER
                      CHIPS, RICE, WEDGES
                    </p>
                  </li>
                  <li>
                    <h4>EXTRA BURGER TOPPINGS</h4>
                  </li>
                </MenuCard>

*/
}

export default RestaurantBody;
