import { Slide } from "react-reveal";
import SpecialOfferCard from "./SpecialOfferCard";
import pizza from "../../assets/images/menu/pizza.jpg";

const SpecialOffersBody = () => {
  return (
    <section className="section section-spacing-40">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <Slide top>
              <div className="section-head center width-300 margin-auto wavetitle-bg">
                <h3 className="section-title title">SPECIAL OFFERS</h3>
              </div>
            </Slide>
            <div className="section-body section-spacing-80">
              <ul className="room-cards-container flex">
                <SpecialOfferCard
                  offerImage={pizza}
                  offerTitle="Twin Tower Hotel Pizza"
                  offerLink="https://form.jotform.com/222191241508549"
                  offerCTATxT="Order Pizza Now"
                >
                  <li>Buy one Pizza, Get one small pizza Free</li>
                  <li>
                    Offer valid only on <b>Public Holidays</b>, <b>Sundays</b>,
                    and <b>Tuesdays</b>{" "}
                  </li>
                </SpecialOfferCard>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SpecialOffersBody;
