import React from "react";
import { Slide } from "react-reveal";

const MapBody = () => {
  return (
    <section className="section section-spacing-40">
      <div className="container wavetitle-bg">
        <div className="large">
          <div className="section-content-container">
            <Slide top>
              <div className="section-head center width-300 margin-auto">
                <h3 className="section-title title">
                  Where is Twin Tower Hotel Located?
                </h3>
                <p className=" margin-top-bottom-10">
                  Twin Tower Hotel is located 4 Kilometers from City Center
                  along Kampala - Bombo Road, Plot 934 Kampala. Get directions
                  here;
                </p>
              </div>
            </Slide>
            <div className="section-body section-spacing-40 no-padding-bottom width-960 margin-auto">
              <div className="map-content">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31917.999185818226!2d32.56281061711552!3d0.3355520843314522!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177dbbb3b41c1d41%3A0x577068ad0a5fdf2d!2sTwin%20Tower%20Hotel!5e0!3m2!1sen!2sug!4v1658998166027!5m2!1sen!2sug"
                  style={{ border: 0 }}
                  allowfullscreen="true"
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MapBody;
