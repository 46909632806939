import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import logo from "../../assets/images/logo/logo.png";

const Header = () => {
  const [showParent, setShowParent] = useState(false);
  return (
    <header className="site-header" id="header">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <nav className="nav">
                <a className="brand" href="/">
                  <img src={logo} alt="Twin Tower Hotel Logo" />
                </a>
                <div className="menu-wrapper relative">
                  <label
                    onClick={() => setShowParent(true)}
                    htmlFor="toggle-responsive-menu"
                    className="nav-label bold"
                  >
                    &#9776;
                  </label>
                  <input type="checkbox" id="toggle-responsive-menu" />
                  <div
                    onClose={() => setShowParent(false)}
                    showParent={showParent}
                    className={`nav-inner ${showParent ? "showParent" : ""}`}
                  >
                    <div className="hide-menu">
                      <button
                        onClick={() => setShowParent(false)}
                        className="hide-menu-btn button"
                      >
                        X
                      </button>
                    </div>
                    <ul
                      className="site-menu bold"
                      onClick={() => setShowParent(false)}
                    >
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="/about">About</a>
                      </li>
                      {/* <li>
                        <a href="/about">Gallery</a>
                      </li>*/}
                      <li>
                        <a
                          target="_blank"
                          href="https://www.google.com/travel/hotels/twin%20tower%20hotel/entity/CgoIrb7_0tCVmrhXEAE/reviews?q=twin%20tower%20hotel&g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4270442%2C4284970%2C4291517%2C4306835%2C4515404%2C4597339%2C4703207%2C4718358%2C4722900%2C4723331%2C4741665%2C4757164%2C4758493%2C4762561%2C4786958%2C4790928%2C4794648%2C4807824%2C4808958%2C4809518%2C4814050%2C4819463%2C4819464&hl=en-UG&gl=ug&ssta=1&rp=EK2-_9LQlZq4VxCtvv_S0JWauFc4AkAASAHAAQI&ictx=1&sa=X&ved=0CAAQ5JsGahcKEwiovurzmqD5AhUAAAAAHQAAAAAQAw&utm_campaign=sharing&utm_medium=link&utm_source=htls&ts=CAESABpJCisSJzIlMHgxNzdkYmJiM2I0MWMxZDQxOjB4NTc3MDY4YWQwYTVmZGYyZBoAEhoSFAoHCOYPEAcYHxIHCOYPEAgYARgBMgIQACoECgAaAA"
                        >
                          Reviews
                        </a>
                      </li>
                      <li>
                        <a href="/#contacts">Contacts</a>
                      </li>
                      <li>
                        <a href="/restaurant">Restaurant</a>
                      </li>
                      <li>
                        <a href="/special-offers">Special Offers</a>
                      </li>
                      <li>
                        <a href="/directions">Location &amp; Map</a>
                      </li>
                      <li>
                        <a href="/accommodation">Accommodation</a>
                      </li>
                      <li>
                        <a href="/facilities">Services &amp; Facilities</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default withRouter(Header);
