import React from "react";
import { Helmet } from "react-helmet";
import AccommodationBody from "../components/accommodation/AccommodationBody";
import AccommodationPageHero from "../components/accommodation/AccommodationPageHero";
import Contacts from "../components/home/Contacts";

const Accommodation = () => {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.twintowerhotel.com/" />
        <meta
          name="description"
          content="A serene and affordable environment for a leisure treat. We are located 4 Kilometers from City Center along Kampala - Bombo Road, Plot 934 kampala."
        />
        <meta
          property="og:description"
          content="A serene and affordable environment for a leisure treat. We are located 4 Kilometers from City Center along Kampala - Bombo Road, Plot 934 kampala."
        />
        <meta property="og:title" content="Rooms | Twin Tower Hotel Uganda" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1668776433/h2_pg82uw.jpg"
        />

        <meta name="twitter:title" content="Rooms | Twin Tower Hotel Uganda" />
        <meta
          name="twitter:text:title"
          content="Rooms | Twin Tower Hotel Uganda"
        />
        <meta
          name="twitter:description"
          content="A serene and affordable environment for a leisure treat. We are located 4 Kilometers from City Center along Kampala - Bombo Road, Plot 934 kampala."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1668776433/h2_pg82uw.jpg"
        />
        <title>Rooms | Twin Tower Hotel Uganda</title>
      </Helmet>
      <AccommodationPageHero />
      <AccommodationBody />
      <Contacts />
    </>
  );
};

export default Accommodation;
