import { Slide } from "react-reveal";
import RoomCard from "./FacilityCard";
import t1 from "../../assets/images/content/t1.jpg";
import t2 from "../../assets/images/content/t2.jpg";
import t3 from "../../assets/images/content/t3.jpg";
import t4 from "../../assets/images/content/t4.jpg";
import c1 from "../../assets/images/content/c1.jpg";
import c2 from "../../assets/images/content/c2.jpg";
import FacilityCard from "./FacilityCard";

const FacilityBody = () => {
  return (
    <section className="section section-spacing-40">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <Slide top>
              <div className="section-head center width-300 margin-auto wavetitle-bg">
                <h3 className="section-title title">Hotel FACILITIES</h3>
              </div>
            </Slide>
            <div className="section-body section-spacing-80">
              <ul className="room-cards-container flex">
                <FacilityCard
                  img1={c1}
                  img2={c1}
                  img3={c1}
                  img4={c1}
                  facilityTitle="Conference Room 1"
                  facilityLink="https://form.jotform.com/tthotelug/book-twin-tower-hotel-conference"
                >
                  <li>80 Seat room</li>
                  <li>Room designed customized to your brand</li>
                  <li>Water bottle per seat</li>
                  <li>White board for presentation</li>
                  <li>Great of corporate meetings</li>
                </FacilityCard>
                <FacilityCard
                  img1={t1}
                  img2={t1}
                  img3={t3}
                  img4={t4}
                  facilityTitle="The Tower Lounge"
                  facilityLink="https://form.jotform.com/tthotelug/book-the-tower-lounge"
                >
                  <li>Has a bar</li>
                  <li>Has a restaurant</li>
                  <li>Spacious for a party and wedding</li>
                </FacilityCard>
                <FacilityCard
                  img1={c2}
                  img2={c2}
                  img3={c2}
                  img4={c2}
                  facilityTitle="Conference Room 1"
                  facilityLink="https://form.jotform.com/tthotelug/book-twin-tower-hotel-conference"
                >
                  <li>40 Seat room</li>
                  <li>Room designed customized to your brand</li>
                  <li>Water bottle per seat</li>
                  <li>White board for presentation</li>
                  <li>Great of corporate meetings</li>
                </FacilityCard>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FacilityBody;
