import React from "react";
const Footer = () => {
  return (
    <section className="section-spacing-20 dark-bg no-padding-bottom">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <ul className=" width-800 margin-auto footer-menu">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/about">About</a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.google.com/travel/hotels/twin%20tower%20hotel/entity/CgoIrb7_0tCVmrhXEAE/reviews?q=twin%20tower%20hotel&g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4270442%2C4284970%2C4291517%2C4306835%2C4515404%2C4597339%2C4703207%2C4718358%2C4722900%2C4723331%2C4741665%2C4757164%2C4758493%2C4762561%2C4786958%2C4790928%2C4794648%2C4807824%2C4808958%2C4809518%2C4814050%2C4819463%2C4819464&hl=en-UG&gl=ug&ssta=1&rp=EK2-_9LQlZq4VxCtvv_S0JWauFc4AkAASAHAAQI&ictx=1&sa=X&ved=0CAAQ5JsGahcKEwiovurzmqD5AhUAAAAAHQAAAAAQAw&utm_campaign=sharing&utm_medium=link&utm_source=htls&ts=CAESABpJCisSJzIlMHgxNzdkYmJiM2I0MWMxZDQxOjB4NTc3MDY4YWQwYTVmZGYyZBoAEhoSFAoHCOYPEAcYHxIHCOYPEAgYARgBMgIQACoECgAaAA"
                  >
                    Reviews
                  </a>
                </li>
                <li>
                  <a href="/contact">Contact Us</a>
                </li>{" "}
                <li>
                  <a href="/directions">Get Directions</a>
                </li>
              </ul>
            </div>
            <div className="footer-copyright uppercase flex space-flex-wrap section-spacing-20">
              <div className="copyright center light-gray">
                <p>&copy; 2022 TWIN TOWER HOTEL LTD. All rights reserved</p>
              </div>
              <div className="designer">
                <div className="design-inner bold right white">
                  <p>
                    Website designed by{" "}
                    <a href="https://ntuna.com" className="link">
                      NTUNA.COM
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
