import React from "react";

const MenuCard = (props) => {
  return (
    <li className="menu-card">
      <div className="menu-title section-spacing-10 uppercase light-yellow dark-bg center">
        <h3>{props.menuTitle}</h3>
      </div>

      <div className="menu-card-body">
        <div className="menu-image">
          <img src={props.menuImage} />
        </div>
      </div>
    </li>
  );
};

export default MenuCard;
