import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { carousel1 } from "../reusables/carousel1";
import { Slide } from "react-reveal";
import h1 from "../../assets/images/content/h1.jpg";
import h2 from "../../assets/images/content/h2.jpg";
import h3 from "../../assets/images/content/h3.jpg";
import h4 from "../../assets/images/content/h4.jpg";
import h5 from "../../assets/images/content/h5.jpg";
import star from "../../assets/images/icons/star.svg";

const HomeHero = () => {
  return (
    <section className="hero">
      <div className="section-content-container">
        <div className="hero-slide carousel relative">
          <div className="slider-items-container center-slide-arrows">
            <Slider {...carousel1}>
              <div className="image-container curtain">
                <img src={h1} alt="Image one" className="sliderImg" />
              </div>
              <div className="image-container curtain">
                <img src={h2} alt="Image one" className="sliderImg" />
              </div>
              <div className="image-container curtain">
                <img src={h3} alt="Image one" className="sliderImg" />
              </div>
              <div className="image-container curtain">
                <img src={h4} alt="Image one" className="sliderImg" />
              </div>
              <div className="image-container curtain">
                <img src={h5} alt="Image one" className="sliderImg" />
              </div>
            </Slider>
          </div>

          <div className="container">
            <div className="large">
              <div className="hero-content-container center centered">
                <div className=" centered-inner">
                  <div className="best-section">
                    <p className="best-text">hotel uganda</p>
                    <div className="best-bars-container flex">
                      <div className="best-bar"></div>
                      <img src={star} className="best-star" />
                      <div className="best-bar"></div>
                    </div>
                  </div>
                  <div className="brand-section">
                    <h2 className="best-text">twin tower</h2>
                  </div>
                  <div className="slogan-section">
                    <p className="best-text">
                      A serene and affordable environment for a leisure treat in
                      Kampala
                    </p>
                  </div>
                  <div className="cta-section flex">
                    <a
                      target="_blank"
                      href="https://form.jotform.com/222141610266545"
                      className="button main-cta bold light-yellow-bg dark"
                    >
                      Book Room
                    </a>
                    <a
                      target="_blank"
                      href="https://form.jotform.com/222141286799565"
                      className="button sub-cta bold dark-bg white"
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
