import React from "react";

const SpecialOfferCard = (props) => {
  return (
    <li className="room-card facility-card so-card">
      <div className="so-image-container">
        <img src={props.offerImage} className="offerImg" />
      </div>
      <div className="room-details">
        <h3 className="room-title">{props.offerTitle}</h3>
        <ul>{props.children}</ul>
      </div>
      <div className="room-cta-container padding-8 center">
        <a
          target="_blank"
          href={props.offerLink}
          className="room-cta light-yellow-bg uppercase dark bold button width-300"
        >
          {props.offerCTATxT}
        </a>
      </div>
    </li>
  );
};

export default SpecialOfferCard;
